import dynamic from 'next/dynamic';

export default {
    //BasePage: dynamic(() => import('./BasePage')),
    PageArticle: dynamic(() => import('./PageArticle')),
    PageEstateObject: dynamic(() => import('./PageEstateObject')),
    PageHome: dynamic(() => import('./PageHome')),
    PageHomeFolkhem: dynamic(() => import('./PageHomeFolkhem')),
    PageIframe: dynamic(() => import('./PageIframe')),
    PageLanding: dynamic(() => import('./PageLanding')),
    PageObjectNotFound: dynamic(() => import('./PageObjectNotFound')),
    PageProject: dynamic(() => import('./PageProject')),
    PageProjectFlexible: dynamic(() => import('./PageProjectFlexible')),
    PageProjectNotFound: dynamic(() => import('./PageProjectNotFound')),
    PageNotFound: dynamic(() => import('./PageNotFound')),
    PageMyProfile: dynamic(() => import('./PageMyProfile')),
    PageMyConfigurations: dynamic(() => import('./PageMyConfigurations')),
    PageMessage: dynamic(() => import('./PageMessage')),

    // TODO: Change naming so it follows proper conventions
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
};
